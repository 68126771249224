import { FC, PropsWithChildren, useMemo } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import {
  createTheme,
  ThemeOptions,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

import { THEME_MODE_STORAGE_KEY } from './constants';
import GlobalStyles from './globalStyles';
import palette from './palette';
import { ThemeMode } from './types';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [themeMode] = useLocalStorage(THEME_MODE_STORAGE_KEY, ThemeMode.light, {
    initializeWithValue: true,
  });
  const theme: ThemeOptions = useMemo(
    () => createTheme({ palette: palette(themeMode) }),
    [themeMode]
  );

  return (
    <>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </MuiThemeProvider>
    </>
  );
};
