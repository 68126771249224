const searchesRootUrl = '/searches';
const searchNumbersRootUrl = '/search-numbers';
const searchVkNumbersRootUrl = '/search-vk-numbers';
const settingsRootUrl = '/settings';
const reportGeneratorRootUrl = '/report-generator';
const multiReportGeneratorRootUrl = '/multi-report-generator';
const bandReportGeneratorRootUrl = '/band-report-generator';
const usersRootUrl = '/users';
const searchByPhotosRootUrl = '/search-photos';
const profilesGroupsRootUrl = '/profiles-groups';
const vkolupailoUrl = '/vk-search';
const bandRootUrl = '/band';

export const ROUTES = Object.freeze({
  auth: Object.freeze({
    login: '/login',
    logout: '/logout',
  }),

  searches: Object.freeze({
    root: searchesRootUrl,
    create: `${searchesRootUrl}/create/:baseSearch?/:targetUser?`,
    map: `${searchesRootUrl}/map`,
    oneTime: `${searchesRootUrl}/onetime`,
    repeat: `${searchesRootUrl}/repeat/:baseSearch?`,
    scheduled: `${searchesRootUrl}/scheduled`,
    scheduledTask: `${searchesRootUrl}/scheduled/:areaId/task`,
    scheduledByAreaId: `${searchesRootUrl}/scheduled/:areaId`,
    scheduledByTaskId: `${searchesRootUrl}/scheduled/:areaId/task/:taskId`,
    scheduledById: `${searchesRootUrl}/scheduled/:areaId/:id`,
    scheduledChats: `${searchesRootUrl}/scheduled/:areaId/:id/chats`,
    scheduledIntersections: `${searchesRootUrl}/scheduled/:areaId/:id/intersections`,
    scheduledByIdTable: `${searchesRootUrl}/scheduled/:areaId/:id/profiles-table`,
    searchById: `${searchesRootUrl}/:id`,
    searchChats: `${searchesRootUrl}/:id/chats`,
    searchIntersections: `${searchesRootUrl}/:id/intersections`,
    searchProfiles: `${searchesRootUrl}/:id/profiles`,
    searchProfilesTable: `${searchesRootUrl}/:id/profiles-table`,

    guardianById: `${searchesRootUrl}/:id/guardian`,
    guardianIntersections: `${searchesRootUrl}/:id/guardian/intersections`,
    guardianProfilesTable: `${searchesRootUrl}/:id/guardian/profiles-table`,
  }),

  reportGenerator: Object.freeze({
    root: reportGeneratorRootUrl,
    byRequestId: `${reportGeneratorRootUrl}/:requestId`,
  }),

  bandReportGenerator: Object.freeze({
    root: bandReportGeneratorRootUrl,
    byBandId: `${bandReportGeneratorRootUrl}/band/:bandId`,
    byRequestId: `${bandReportGeneratorRootUrl}/:groupId`,
  }),

  multiReportGenerator: Object.freeze({
    root: multiReportGeneratorRootUrl,
    byRequestId: `${multiReportGeneratorRootUrl}/:requestId`,
  }),

  searchesByNumbers: Object.freeze({
    root: searchNumbersRootUrl,
    searchById: `${searchNumbersRootUrl}/:id`,
    vkRoot: `${searchVkNumbersRootUrl}`,
    vkSearchById: `${searchVkNumbersRootUrl}/:id`,
    create: `${searchNumbersRootUrl}/create`,
  }),

  searchBands: Object.freeze({
    root: '/search-bands',
  }),

  searchPhotos: Object.freeze({
    root: searchByPhotosRootUrl,
    searchById: `${searchByPhotosRootUrl}/:id`,
    create: `${searchByPhotosRootUrl}/create`,
  }),

  users: Object.freeze({
    root: usersRootUrl,
    userById: `${usersRootUrl}/:id`,
    userProfile: `${usersRootUrl}/:id/profile/:tab?/:subTab?`,
    userDossier: `${usersRootUrl}/:id/profile/dossier`,
    userLocation: `${usersRootUrl}/:userId/location/:searchId`,
    userPositions: `${usersRootUrl}/:userId/positions/:searchId?`,
  }),

  notifications: Object.freeze({
    root: '/notifications',
  }),

  settings: Object.freeze({
    root: settingsRootUrl,
  }),

  profilesGroups: Object.freeze({
    root: profilesGroupsRootUrl,
    my: {
      root: `${profilesGroupsRootUrl}/my`,
      byGroupId: `${profilesGroupsRootUrl}/my/:groupId`,
    },
    organization: {
      root: `${profilesGroupsRootUrl}/organization`,
      byGroupId: `${profilesGroupsRootUrl}/organization/:groupId`,
    },
    byGroupId: `${profilesGroupsRootUrl}/:groupId`,
  }),

  vkolupailo: Object.freeze({
    root: vkolupailoUrl,
    byParams: `${vkolupailoUrl}/params`,
    photosWithGeoData: `${vkolupailoUrl}/photos-with-geo-data`,
    profileRoot: `${vkolupailoUrl}/profile`,
    profile: `${vkolupailoUrl}/profile/:profileId`,
    friends: `${vkolupailoUrl}/profile/:profileId/friends`,
    groupRoot: `${vkolupailoUrl}/group`,
    group: `${vkolupailoUrl}/group/:groupId`,
    subscribers: `${vkolupailoUrl}/group/:groupId/subscribers`,
    phoneRoot: `${vkolupailoUrl}/phone`,
    phone: `${vkolupailoUrl}/phone/:phone`,
  }),

  telezip: Object.freeze({
    root: '/telezip',
  }),

  band: Object.freeze({
    root: `${bandRootUrl}`,
    bandById: `${bandRootUrl}/:id`,
    bandGeneral: `${bandRootUrl}/:id/general`,
    bandTab: `${bandRootUrl}/:id/:tab/:subTab?`,
  }),

  map: Object.freeze({
    root: '/map',
    mapVariant: '/map/:variant',
  }),

  notFound: '/not-found',
});
