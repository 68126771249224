import { toast, ToastContent } from 'react-toastify';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

export const showSuccessMessage = (msg: string) =>
  toast.success(msg, {
    className: 'success-toaster',
    position: toast.POSITION.BOTTOM_LEFT,
    icon: <CheckCircleIcon color="success" />,
  });

export const showWarningMessage = (msg: ToastContent, options?: { closeOnClick?: boolean }) =>
  toast.warning(msg, {
    className: 'warning-toaster',
    position: toast.POSITION.BOTTOM_LEFT,
    icon: <WarningIcon color="warning" />,
    theme: 'colored',
    closeOnClick: options?.closeOnClick,
  });

export const showErrorMessage = (msg: string) =>
  toast.error(msg, {
    className: 'error-toaster',
    position: toast.POSITION.BOTTOM_LEFT,
    icon: <ErrorIcon color="error" />,
  });
